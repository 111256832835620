import {
    forum_tool
  } from "../../js/path";
  
  export default {
    data() {
      return {
        brand:"",
        brands: [],
        fieldReps:[],
        fieldRep:null,
      };
    },
    methods: {
      fieldRepChanged(){
        this.locations = []
        if(this.fieldRep)
        {
          if(this.fieldRep.latitude && this.fieldRep.langitude)
          {
            this.locations.push({
              position: {
                lat: Math.floor(this.fieldRep.latitude),
                lng: Math.floor(this.fieldRep.langitude)
              },
              label: this.fieldRep.address
            })
          }
        }
        // console.log(this.fieldRep.first_name)
      },
      async fetchBrand() {
        let url = null; 
        this.$store.commit("loader/updateStatus", true);
        url = forum_tool.forumToolUrl+"?nopagination"
        try {
          const data = await this.getRequest(url);
          if (data.status) {
            const responseData = data.response;
            this.brands = responseData.data;
          }
        } catch (err) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: err.data ? err.data.message : "Please try again!",
          });
        }
        this.$store.commit("loader/updateStatus", false);
      },
      async fetchFieldReps() {
        this.fieldReps = []
        this.locations = []
        if(this.brand=="")
        {
          return ;
        }
        let url = null; 
        this.$store.commit("loader/updateStatus", true);
        url = forum_tool.getFieldRepsByBrand+"/"+this.brand+"?nopagination"
        try {
          const data = await this.getRequest(url);
          if (data.status) {
            const responseData = data.response;
            this.fieldReps = responseData.data;
            console.log(this.fieldReps)
          }
        } catch (err) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: err.data ? err.data.message : "Please try again!",
          });
        }
        this.$store.commit("loader/updateStatus", false);
      },
      
    },
    mounted() {
      this.fetchBrand(this.activeTab);
  }
};