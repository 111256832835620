<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row" id="newsletter">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row align-items-center mb-4">
              <div class="col-md-2">
                <label class="d-block">Brand</label>
                  <select class="form-control bg-light border-0 small" v-model="brand" @change="fetchFieldReps()">
                    <option value="">Select</option>
                    <option v-for="item in brands" :value="item.id" :key="item.id">{{ item.forum_name }}</option>
                  </select>
              </div>
              <div class="col-md-2">
                <label class="d-block">Field Reps</label>
                  <select class="form-control bg-light border-0 small" v-model="fieldRep" @change="fieldRepChanged()">
                    <option value="">Select</option>
                    <option v-for="item in fieldReps" :value="item" :key="item.id">{{ item.first_name+" "+item.last_name }}</option>
                  </select>
              </div>
              <div class="col-md-2">
                    <label class="d-block">&nbsp;</label>
              <button class="btn btn-search">
                        <i class="fas fa-search fa-sm"></i>
                      </button>
              </div>
              <div class="col-md-6">
                <div class="all-tabs">
                  <!-- Add tabs or other content here -->
                </div>
              </div>
            </div>
            <div>
              <gmap-map
                :center="center"
                :zoom="5"
                style="width: 100%; height: 71vh;"
              >
                <gmap-marker
                  :key="index"
                  v-for="(gmp, index) in locations"
                  :position="gmp.position"
                  @click="togglePopup(gmp)"
                >
                  <gmap-info-window
                    v-if="currentMarker === gmp"
                    :options="{ maxWidth: 200 }"
                    :position="gmp.position"
                    :ref="'infoWindow' + index"
                  >
                    <div v-html="gmp.label"></div>
                  </gmap-info-window>
                </gmap-marker>
              </gmap-map>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import MixinRequest from "../../../mixins/request";
import FieldMrMixin from "../../../mixins/ModuleJs/field-rep";

export default {
  mixins: [MixinRequest, FieldMrMixin],
  name: "DrawGoogleMap",
  data() {
    return {
      title: "Field Rep Tracking",
      items: [],
      center: {
        lat: 22.3511148,
        lng: 78.6677428
      },
      locations: [],
      currentMarker: null,
      openMarkers: null,
    };
  },
  methods: {
    togglePopup(location) {
      if (this.currentMarker === location) {
        this.currentMarker = null;
        this.openMarkers = null;
      } else {
        this.currentMarker = location;
        this.openMarkers = [location];
      }
    }
  },
  mounted() {
    this.locations = [];
  },
  components: {
    Layout,
    PageHeader,
  }
};
</script>
